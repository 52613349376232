import { Route, Routes } from "react-router-dom";
import Nav from './Components/Navbar';
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import About from "./Components/About";
import LeadToLeader from "./Components/LeadToLeader";
import Articles from "./Components/Articles";
import MoveToTop from "./Components/MoveToTop";


function App() {
  
  return (
    <>
      <Nav/>
      <MoveToTop/>

      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/About" element={<About/>}/>
        <Route path="/Articles" element={<Articles/>}/>
        <Route path="/leadtoleader" element={<LeadToLeader/>}/>
      </Routes>
      
      <Footer/> 
    </>
  );
}

export default App;

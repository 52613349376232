import React, { useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


const LeadToLeader = () => {

  return (
    <div className='ResumePage'>

    </div>
  )
}

export default LeadToLeader
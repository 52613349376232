import React from 'react';
import {FaGithub, FaLinkedin, FaTwitter} from "react-icons/fa";
import {GrMail} from "react-icons/gr";
import {SiLeetcode} from "react-icons/si"

const Footer = () => {
  return (
    <footer>
      <div className='footerLinks'>
        <a href="https://www.linkedin.com/in/jtonzing/" target='_blank'><FaLinkedin/></a>
         {/* <a href='https://twitter.com/jtonzing' target='_blank'><FaTwitter/></a> */}
      </div>
    </footer>
  )
}

export default Footer
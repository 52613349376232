import React from 'react';

import Tilt from 'react-parallax-tilt';
import Portrait from '../images/PortraitCartoon.png';

const Home = () => {
  return (
    <div >
      <div className='HomePage'>

        <div className='HomeText'>
          <h1>Hi There!</h1>
          <h1>I'm <b>Josh Tonzing</b></h1>
          <br />
          <h2>Engineering leader. Agile expert. Nerd</h2>
          <br /> 
          <h3>Dedicated to helping you make your engineering team better</h3> 
        </div>
        <img className='Avatar' src={Portrait} alt="" />
      </div>
    </div>
  )
}

export default Home
import React from 'react';
import { FaLinkedin } from "react-icons/fa";
import { SlSocialSoundcloud } from "react-icons/sl";
import { RiSpotifyLine } from "react-icons/ri";
import { SiApplemusic } from "react-icons/si";
import Intro from '../images/1689048904650.png';
import WinWin from '../images/1689833197887.png';
import Constraints from '../images/1706764688702.png';
import DevOps from '../images/3fe23e6a-fdc4-4312-b51d-769392c5dd2e.png';
import QAFirst from '../images/e6fd738e-c0ac-400c-a299-084bbb87323b.png';
import Velocity from '../images/1658367665807.jpg';
import Invert from '../images/af110249-3f26-485d-9bf3-8b02f591b503.png';
import Inefficient from '../images/1709167840677.png';

const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>My <b>articles</b></h1>


      <div className='project'>
      <h2>Habits of highly effective engineers series</h2>
        <div className='projectBox'> 
            <img className='projectPhoto' src={Intro} alt="An introduction" /> 
            <div>
                <br />
                <h3>Habits of highly effective engineers - An introduction</h3>
                <br />
                An overview of my 'Habits of highly effective engineers' series.'
                <br />
                <a href={"https://www.linkedin.com/pulse/habits-highly-effective-engineers-introduction-josh-tonzing-8guoc"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
            </div>
        </div>
        <div className='projectBox'> 
            <img className='projectPhoto' src={WinWin} alt="Think win-win" /> 
            <div>
                <br />
                <h3>Habits of highly effective engineers - Think win-win</h3>
                <br />
                How the bureaucracy of risk management can prevent an engineering team from delivering fast, high-quality outcomes for your project.
                <br />
                <a href={"https://www.linkedin.com/pulse/habits-highly-effective-engineers-1-think-win-win-josh-tonzing-cbtef"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
            </div>
        </div>
        <div className='projectBox'> 
            <img className='projectPhoto' src={Constraints} alt="Know your constraints" /> 
            <div>
                <br />
                <h3>Habits of highly effective engineers - Know your constraints</h3>
                <br />
                How teams waste effort improving processes in the wrong places, how to identify where your issues actually are, and what they look like.
                <br />
                <a href={"https://www.linkedin.com/pulse/habits-highly-effective-engineers-1-think-win-win-josh-tonzing-cbtef"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
            </div>
        </div>
        <div className='projectBox'> 
            <img className='projectPhoto' src={Inefficient} alt=" Be inefficient" /> 
            <div>
                <br />
                <h3>Habits of highly effective engineers - Be inefficient</h3>
                <br />
                I discuss how software engineers spend too much time planning their solutions. They will over-invest in the planning phase in an attempt to build a scalable system that meets all future needs. A gamble that is often at best a marginal benefit and at worst a project killer.
                <br />
                <a href={"https://www.linkedin.com/pulse/copy-habits-highly-effective-engineers-inefficient-josh-tonzing-mefzc/"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
            </div>
        </div>
      </div>
      <div className='project'>
      <h2>Podcasts</h2>
        <div className='projectBox'>  
          <div>
              <br />
              <h3>Buillding a Culture of Innovation within your Development Team </h3>
              <br />
              What innovation looks like to them, strategies to engage, measure and promote an innovative culture.
              <br />
              
              <a href={"https://soundcloud.com/user-401188774/evo-145-buillding-a-culture-of-innovation-within-your-development-team-1?utm_source=clipboard&utm_campaign=wtshare&utm_medium=widget&utm_content=https%253A%252F%252Fsoundcloud.com%252Fuser-401188774%252Fevo-145-buillding-a-culture-of-innovation-within-your-development-team-1"} target='_blank'>
                <button className='projectbtn'><SlSocialSoundcloud/> Soundcloud</button>
              </a>
              <a href={"https://open.spotify.com/episode/3ftfwtSopMuUmQw4CCj0k3"} target='_blank'>
                <button className='projectbtn'><RiSpotifyLine /> Spotify</button>
              </a>
              <a href={"https://podcasts.apple.com/us/podcast/evo-145-buillding-a-culture-of-innovation-within/id1612661643?i=1000624876706"} target='_blank'>
                <button className='projectbtn'><SiApplemusic/> Apple Music</button>
              </a>
          </div>
        </div>
      </div>

      <div className='project'>
        <h2>Other articles</h2>
        <div className='projectBox'> 
            <img className='projectPhoto' src={DevOps} alt="Can DevOps defeat The Mythical Man Month?" /> 
            <div>
                <br />
                <h3>Can DevOps defeat The Mythical Man Month?</h3>
                <br />
                Using DevOps to address issues of scale in an engineering team.
                <br />
                <a href={"https://www.linkedin.com/pulse/can-devops-defeat-mythical-man-month-josh-tonzing/"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
            </div>
        </div>
        <div className='projectBox'> 
            <img className='projectPhoto' src={QAFirst} alt="Speed up your development by doing QA before code" /> 
            <div>
                <br />
                <h3>Speed up your development by doing QA before code</h3>
                <br />
                Many engineering teams introduce inefficiency by failing to address risks until it is too late. Move your QA process before the coding starts to remove risk and increase velocity.
                <br />
                <a href={"https://www.linkedin.com/pulse/qa-should-happen-before-development-after-josh-tonzing"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
          </div>
        </div>
          <div className='projectBox'> 
            <img className='projectPhoto' src={Velocity} alt="Measuring sprint velocity can ruin your project" /> 
            <div>
                <br />
                <h3>Measuring sprint velocity can ruin your project</h3>
                <br />
                A commonly used metric for measuring the success of your engineering team. When used outside of a very explicit set of bounds, its utilisation will ensure that bad outcomes will always be folded into your workload.
                <br />
                <a href={"https://www.linkedin.com/pulse/fixed-velocity-ruins-projects-josh-tonzing"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
            </div>
          
        </div>
        <div className='projectBox'> 
            <img className='projectPhoto' src={Invert} alt="Invert your engineering team" /> 
            <div>
                <br />
                <h3>Invert your engineering team</h3>
                <br />
                Engineering teams often put a reliance on their senior engineers driving the teams success. This can be a critical mistake where small gains result in large losses tomorrow.
                <br />
                <a href={"https://www.linkedin.com/pulse/invert-your-engineering-team-josh-tonzing"} target='_blank'>
                  <button className='projectbtn'><FaLinkedin/> Read</button>
                </a>
          </div>
          </div>
      </div>

    </div>
  )
}

export default Projects